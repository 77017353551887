import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/alata";
import "@fontsource/asap";

import {
  AdsModal,
  DomainTypeModal,
  FunctioningLinksModal,
  InformationModalOpener,
  ReferencesModal,
} from "~/components/modals";

interface ContainsModals {
  modalFunctions: ((arg0: boolean) => void)[];
  modalRatings: number[];
}

export const Header = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="bg-white flex-row sm:flex">
      <div className="xl:w-1/4 flex md:justify-around lg:justify-center">
        <h1 className="text-safe-environment-indigo-900 lg:flex-wrap text-3xl sm:w-1/3 md:w-full lg:w-2/3 xl:w-5/6 m-4 lg:ml-12 lg:mt-10 lg:mr-0 lg:mb-6">
          Foundation for a Safe Environment
        </h1>
      </div>
      <div className="xl:w-3/4 flex justify-end flex-wrap xl:flex-col">
        <div className="lg:mt-8 mb-6 mr-2 lg:mr-8 xl:mb-5 xl:mt-4 xl:h-1/4 xl:flex xl:justify-end mt-2">
          <a className="font-black text-3xl relative" href="#" style={{ fontFamily: "Asap" }}>
          <InformationModalOpener
            className="absolute left-0 top-0 -ml-12"
            rating={modalRatings[0]}
            onClick={() => modalFunctions[0](true)}
          />
          www.fsafeenviron.org</a>
        </div>
        <div className="bg-safe-environment-blue-800 rounded-tl-3xl flex flex-row w-max xl:self-end items-end relative">
          <Link to="/article/safe-environment" className="text-lg text-white sm:py-2 lg:py-4 md:px-20 xl:px-32 px-3">Home</Link>
          <Link className="text-lg text-white sm:py-2 lg:py-4" to="/article/safe-environment/about-us">About Us</Link>
          <Link className="text-lg text-white sm:py-2 lg:py-4 md:px-20 xl:px-32 px-4" to="/article/safe-environment/contact-us">Contact Us</Link>
          <InformationModalOpener
            className="mr-4 sm:absolute sm:right-0 sm:top-0"
            rating={modalRatings[1]}
            onClick={() => modalFunctions[1](true)}
          />
        </div>
      </div>
    </div>
  );
};

const SafeEnvironmentBanner = (): JSX.Element => {
  return (
    <div
      className="relative"
    >
      <StaticImage
        alt="Students cell phones"
        loading="eager"
        placeholder="blurred"
        src="../../../images/safe-environment.jpg"
      />
      <div
        className="bg-white opacity-75 lg:w-1/3 text-black p-6 text-sm float-right sm:absolute right-0 bottom-0"
      >
        <p className="">The Foundation for a Safe Environment is a nonprofit organization focused on to educating people about health concerns from our increasing dependence on technology. Our work is carried out by a small team of scientists and other concerned people who volunteer their time and expertise.</p>
        <p className="pt-5">Each month, our website focuses on a different technology issue. Past topics have included acid rain, nutrient pollution, carbon emissions, and recycling of electronics. This month we are addressing health concerns that arise from the use of cell phones.</p>
      </div>
    </div>
  );
};

const Body = ({ modalFunctions, modalRatings }: ContainsModals): JSX.Element => {
  return (
    <div className="flex w-full flex-wrap relative">
      <div className="md:w-1/2 mt-12 lg:mt-16 ml-20">
        <h2 className="text-3xl text-safe-environment-indigo-900">Important Facts About Cell Phone Use</h2>
        <div className="w-3/4 text-sm mb-14 relative">
          <ul className="" style={{  listStyleType: "disc", listStylePosition: "inside"}} >
            <li className="mt-6">All cell phones emit radiation. Cell phones work by sending and receiving signals to and from cell phone towers. These signals are a form of electromagnetic radiation called radiofrequency (RF) energy.</li>
            <li className="mt-6">Laboratory experiments and human health studies suggest that long-term, high use of cell phones may be linked to cancer and other health problems including lowered sperm counts, headaches, loss of sleep, and problems with learning and memory.</li>
            <li className="mt-6">Cell phone safety is not regulated by the Food and Drug Administration.</li>
            <li className="mt-6">Industry has tried to discredit researchers who claim that cell phone use causes health problems.</li>
          </ul>
          <InformationModalOpener
            className="absolute right-0 top-0 -mr-10"
            rating={modalRatings[0]}
            onClick={() => modalFunctions[0](true)}
          />
        </div>
      </div>
      <div className="lg:w-2/5 flex place-items-center lg:mt-12">
        <p className="bg-safe-environment-green rounded-2xl text-center text-black text-2xl flex-wrap px-8 py-6 mx-4 md:mt-6 mb-4 lg:mx-0 lg:mb-0">Support our important work by making a tax-deductible donation!</p>
      </div>
      <InformationModalOpener
        className="absolute right-0 top-0 mr-10 mt-4 md:m-10"
        rating={modalRatings[1]}
        onClick={() => modalFunctions[1](true)}
      />
    </div>
  );
};

export const Footer = ( ): JSX.Element => {
  return (
    <div className="bg-safe-environment-blue-800 flex justify-around md:justify-end py-6">
      <Link to="/article/safe-environment" className="text-lg text-white px-2 md:px-32">Home</Link>
      <Link className="text-lg text-white" to="/article/safe-environment/about-us">About Us</Link>
      <Link className="text-lg text-white px-2 md:px-32" to="/article/safe-environment/contact-us">Contact Us</Link>
    </div>
  );
};

const SafeEnvironmentHomePage = (): JSX.Element => {
  const [domainTypeModalVisible, setDomainTypeModalVisible] = useState<boolean>(false);
  const [domainTypeModalRating, setDomainTypeModalRating] = useState<number>(-1);
  const [referencesModalVisible, setReferencesModalVisible] = useState<boolean>(false);
  const [referencesModalRating, setReferencesModalRating] = useState<number>(-1);
  const [functioningLinksModalVisible, setFunctioningLinksModalVisible] = useState<boolean>(false);
  const [functioningLinksModalRating, setFunctioningLinksModalRating] = useState<number>(-1);
  const [adsModalVisible, setAdsModalVisible] = useState<boolean>(false);
  const [adsModalRating, setAdsModalRating] = useState<number>(-1);

  return (
    <div style={{ fontFamily: "Alata" }}>
      <Header
        modalFunctions={[setDomainTypeModalVisible,setFunctioningLinksModalVisible]}
        modalRatings={[domainTypeModalRating,functioningLinksModalRating]} />
      <SafeEnvironmentBanner />
      <Body modalFunctions={[setReferencesModalVisible, setAdsModalVisible]}
        modalRatings={[referencesModalRating, adsModalRating]} />
      <Footer />
      {domainTypeModalVisible && (
        <DomainTypeModal
          modalRating={domainTypeModalRating}
          modalVisible={domainTypeModalVisible}
          setModalRating={setDomainTypeModalRating}
          setModalVisible={setDomainTypeModalVisible}
        />
      )}
      {referencesModalVisible && (
        <ReferencesModal
          modalRating={referencesModalRating}
          modalVisible={referencesModalVisible}
          setModalRating={setReferencesModalRating}
          setModalVisible={setReferencesModalVisible}
        />
      )}
      {functioningLinksModalVisible && (
        <FunctioningLinksModal
          modalRating={functioningLinksModalRating}
          modalVisible={functioningLinksModalVisible}
          setModalRating={setFunctioningLinksModalRating}
          setModalVisible={setFunctioningLinksModalVisible}
        />
      )}
      {adsModalVisible && (
        <AdsModal
          modalRating={adsModalRating}
          modalVisible={adsModalVisible}
          setModalRating={setAdsModalRating}
          setModalVisible={setAdsModalVisible}
        />
      )}
    </div>
  )
};

export default SafeEnvironmentHomePage;
